import React from 'react';
import { graphql } from 'gatsby'

import Layout from '../components/Layout';
import useMatchedQuery from '../hooks/useMatchedQuery';

import ContentfulComponentSeo from '../ContentfulComponents/ContentfulComponentSeo';

import ContentfulComponentCarousel from '../ContentfulComponents/ContentfulComponentCarousel';

import ContentfulComponentHero from '../ContentfulComponents/ContentfulComponentHero';

import ContentfulComponentDynamic from '../ContentfulComponents/ContentfulComponentDynamic';

import ContentfulComponentUpdatedGames from '../ContentfulComponents/ContentfulComponentUpdatedGames';


export const query = graphql`
query {
 

    contentfulComponentSeo0: contentfulComponentSeo (node_locale: {eq: "es-AR"}, contentful_id: {eq: "45j2wu6ofgUPKO6TbhBB9X"}) {
      
  title
  type
  description {
    description
  }
  image {
    id
    title
    gatsbyImageData(
      layout: FULL_WIDTH,
      placeholder: BLURRED,
    )
  }

      internal {
        type
      }    
    }
  





    contentfulComponentCarousel1: contentfulComponentCarousel (node_locale: {eq: "es-AR"}, contentful_id: {eq: "56ZgxX9kU7NZ2ruZ3l73M"}) {
      
  animationSpeed
  autoplay
  autoplayDelayBetweenSlides
  backgroundColor
  bias
  border
  borderColor
  boxShadow
  contentful_id
  controls
  controlsHeight
  controlsWidth
  cornerRadius
  direction
  disable3d
  displayableSlides
  height
  id
  interactive
  inverseScaling
  loop
  name
  paddingBottom
  paddingTop
  pauseOnHover
  perspective
  slides {
    ...Slide
  }
  spaceBetweenSlides
  title
  width

      internal {
        type
      }    
    }
  





    contentfulComponentHero2: contentfulComponentHero (node_locale: {eq: "es-AR"}, contentful_id: {eq: "4o3qzH77QwdIy5fnFMZBok"}) {
      
id
title
contentful_id
node_locale
codeHero
gridModel
gridPosition
portraitGridModel
portraitGridPosition
textPosition
textColor
image {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
portraitImage {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
overlayText {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentCarousel3: contentfulComponentCarousel (node_locale: {eq: "es-AR"}, contentful_id: {eq: "14JLMItfuECTMTEi5ui0PQ"}) {
      
  animationSpeed
  autoplay
  autoplayDelayBetweenSlides
  backgroundColor
  bias
  border
  borderColor
  boxShadow
  contentful_id
  controls
  controlsHeight
  controlsWidth
  cornerRadius
  direction
  disable3d
  displayableSlides
  height
  id
  interactive
  inverseScaling
  loop
  name
  paddingBottom
  paddingTop
  pauseOnHover
  perspective
  slides {
    ...Slide
  }
  spaceBetweenSlides
  title
  width

      internal {
        type
      }    
    }
  





    contentfulComponentDynamic4: contentfulComponentDynamic (node_locale: {eq: "es-AR"}, contentful_id: {eq: "55fHVoOS9T3ZMMbFQ3Ga3l"}) {
      
dynamicComponent {
  dynamicComponent
}
name
title
component
portraitImage {
  id
  gatsbyImageData
  title
  description
}
landscapeImage {
  gatsbyImageData
  id
  title
  description
}
text {
  text
}
contentful_id
id
richText {
  raw
}

      internal {
        type
      }    
    }
  


      dynamicComonentQueryGamesCounter_totalGames:allContentfulGame(filter: {expansion: {eq: false}, onlyForSale: {ne: true}, cities: {elemMatch: {name: {eq: "ba"}}}}) {
        totalGames: totalCount
      }
    


      dynamicComonentQueryGamesCounter_totalGamesForSale:allContentfulGameForSale(filter: {listed: {ne: false}}) {
        totalGamesForSale: totalCount
      }
    


      dynamicComonentQueryGamesCounter_totalGamesCommingSoon:allContentfulGame(filter: {expansion: {eq: false}, listed: {ne: false}, available: {eq: false}, onlyForSale: {ne: true}, cities: {elemMatch: {name: {eq: "ba"}}}}) {
        totalGamesCommingSoon: totalCount
      }
    


      dynamicComonentQueryGamesCounter_totalExpansions:allContentfulGame(filter: {expansion: {eq: true}, onlyForSale: {ne: true}, cities: {elemMatch: {name: {eq: "ba"}}}}) {
        totalExpansions: totalCount
      }
    



    contentfulComponentDynamic5: contentfulComponentDynamic (node_locale: {eq: "es-AR"}, contentful_id: {eq: "th9ONwLo9GoCPjFhNHfwZ"}) {
      
dynamicComponent {
  dynamicComponent
}
name
title
component
portraitImage {
  id
  gatsbyImageData
  title
  description
}
landscapeImage {
  gatsbyImageData
  id
  title
  description
}
text {
  text
}
contentful_id
id
richText {
  raw
}

      internal {
        type
      }    
    }
  


      dynamicComonentQueryGamesCarousel_latest20additions:allContentfulGame(filter: {cities: {elemMatch: {name: {eq: "ba"}}}, listed: {ne: false}, onlyForSale: {ne: true}}     limit: 30     sort: {fields: createdAt, order: DESC}) {
        totalCount
        edges {
          node {
            ...GameIndexEntry
          }
  
        }
      }
    



    contentfulComponentUpdatedGames6: contentfulComponentUpdatedGames (node_locale: {eq: "es-AR"}, contentful_id: {eq: "4qjkzUMeLviWlzyn2zqtnL"}) {
      
id
contentful_id
node_locale
name
title
days

      internal {
        type
      }    
    }
  



 
 
 site {
  siteMetadata {
    siteUrl
  }
}}

`

const Page = (props) => {
  const seo = props.pageContext.seo;
  const matchedQuery = useMatchedQuery()
  const { contentIds, ...context } = props.pageContext
  const { data } = props;
  return (
    <Layout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={true} >
      
      <ContentfulComponentSeo {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentSeo0" pathName="/" />
      
      <ContentfulComponentCarousel {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentCarousel1" pathName="/" />
      
      <ContentfulComponentHero {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentHero2" pathName="/" />
      
      <ContentfulComponentCarousel {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentCarousel3" pathName="/" />
      
      <ContentfulComponentDynamic {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentDynamic4" pathName="/" />
      
      <ContentfulComponentDynamic {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentDynamic5" pathName="/" />
      
      <ContentfulComponentUpdatedGames {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={true} componentId="contentfulComponentUpdatedGames6" pathName="/" />
      
    </Layout>
  )
}

export default Page;
