import React from 'react';
// import Img from "gatsby-image"
import BoardGameImageCard from '../../components/Cards/GameImage';
import BoardGameCard from '../../components/Cards/Game';
import { GatsbyImage } from "gatsby-plugin-image"


const RenderCard = (props) => {
  const { slide, carousel } = props;
  const type = (slide.entity) ? slide.entity.type : slide.type
  switch (type) {
    case 'ContentfulGame':
      return (
        <BoardGameCard slide={slide} carousel={carousel} />
      )
    case 'boardgameimage':
        return (
          <BoardGameImageCard slide={slide} carousel={carousel} />
        )
    default:      
      return (
        <GatsbyImage
          image={slide.image.gatsbyImageData}
          alt={slide.name}
          width={360}
          height={360}
        />
      )
  }
}

export default RenderCard;
