import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UpdatedGames from '../../components/UpdatedGames';

export default (props) => {
  const data = props.data[props.componentId];
  const title = (data && data.title);

  return (
    <Grid container direction="row" spacing={3} style={{contentVisibility: 'auto'}}>
    <Grid item xl={12}>
      <Typography variant="h3" component="h4">{title}</Typography>
    </Grid>
    <Grid item xl={12}>
      <UpdatedGames days={data.days} />

    </Grid>

    </Grid>
  )
}